<template>
  <div class="NewRetailPart2">
    <div
      class="module3-bg"
      :style="{
        backgroundImage: `url(${
          $StaticFileUrl +
          '/mp/pages/Solution/newRetail/newRetail-module3-bg.png'
        })`,
      }"
    ></div>
    <div class="section-title">平台能力</div>
    <div class="section-subtitle">
      整合互动广告资源及福利权益资源，一键自定义搭建在线商城，提供多场景解决方案
    </div>
    <div class="module3-cont">
      <div class="module3-left">
        <div class="module3-img-box">
          <img
            :src="
              $StaticFileUrl +
              '/mp/pages/Solution/newRetail/newRetail-module3-left.png'
            "
            alt=""
            class="module3-img"
          />
        </div>
        <dl class="module3-dl">
          <dt class="module3-dt">平台资源</dt>
          <dd
            class="module3-dd"
            v-for="(item, index) in NewRetailPart2_Data.left"
            :key="index"
          >
            <div class="point"></div>
            <p class="module3-dd-text">{{ item }}</p>
          </dd>
        </dl>
      </div>
      <div class="module3-middle">
        <div class="module3-middle-top">
          <div
            class="module3-middle-item"
            v-for="(item, index) in NewRetailPart2_Data.middle"
            :key="index"
          >
            <div class="middle-item-box">
              <img
                :src="
                  $StaticFileUrl +
                  '/mp/pages/Solution/newRetail/newRetail-module3-gou.png'
                "
                alt=""
                class="module3-middle-gou"
              />
              <p class="module3-middle-item-text">{{ item }}</p>
            </div>
          </div>
        </div>
        <img
          class="module3-img"
          :src="
            $StaticFileUrl +
            '/mp/pages/Solution/newRetail/newRetail-module3-img.png'
          "
          alt=""
        />
      </div>
      <div class="module3-right">
        <div class="module3-img-box">
          <img
            :src="
              $StaticFileUrl +
              '/mp/pages/Solution/newRetail/newRetail-module3-right.png'
            "
            alt=""
            class="module3-img"
          />
        </div>
        <dl class="module3-dl">
          <dt class="module3-dt">平台功能</dt>
          <dd
            class="module3-dd"
            v-for="(item, index) in NewRetailPart2_Data.right"
            :key="index"
          >
            <div class="point"></div>
            <p class="module3-dd-text">{{ item }}</p>
          </dd>
        </dl>
      </div>
    </div>
  </div>
</template>

<script>
import NewRetailPart2_Data from "@/views/Solution/NewRetail/Data/NewRetailPart2_Data";

/**
 * @author  XuHongli
 * @date  2022/12/27 16:49
 * @version 1.0
 * @description
 */
import WOW from "wowjs";
export default {
  name: "NewRetailPart2",
  data() {
    return {
      NewRetailPart2_Data,
    };
  },
  mounted() {
    new WOW.WOW().init();
  },
};
</script>

<style scoped lang="scss">
.NewRetailPart2 {
  padding: 60px 0 20px;
  position: relative;
  background-color: #f6f7f8;
  .module3-bg {
    width: 100%;
    padding-top: 43.229%;
    background-size: 100%;
    position: absolute;
    left: 0;
    top: 0;

    background-color: #fff;
  }
  .section-title {
    font-size: 44px;
    font-weight: 400;
    color: #222;
    z-index: 10;
    text-align: center;
    transform: translateZ(100px);
  }
  .section-subtitle {
    font-size: 18px;
    color: #222;
    z-index: 10;
    transform: translateZ(100px);
    font-weight: 400;
    text-align: center;
    margin-top: 10px;
  }
}
.module3-cont {
  display: flex;
  justify-content: center;
  margin-top: 123px;
  .module3-left,
  .module3-right {
    position: relative;
    .module3-img-box {
      position: absolute;
      left: 50%;
      top: 0;
      width: 86px;
      height: 86px;
      border-radius: 50%;
      background-color: #fff;
      box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
      transform: translate(-50%, -50%);
      display: flex;
      justify-content: center;
      align-items: center;
      .module3-img {
        width: 61px;
        height: 60px;
      }
    }
  }
}
.module3-dl {
  width: 210px;
  height: 452px;
  background-color: #fff;
  border-radius: 8px;
  padding: 55px 0 0 36px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
  .module3-dt {
    font-size: 24px;
    color: #222;
    font-weight: 500;
  }
  .module3-dd {
    font-size: 18px;
    color: #222;
    font-weight: 400;
    margin-top: 20px;
    display: flex;
    align-items: center;
    &:first-of-type {
      margin-top: 25px;
    }
    .point {
      width: 8px;
      height: 8px;
      background-color: #cdcdcd;
      margin-right: 8px;
    }
    .module3-dd-text {
      font-size: 18px;
    }
  }
}
.module3-middle {
  margin: 0 162px;
  position: relative;
  .module3-middle-top {
    width: 100%;
    height: 80px;
    position: relative;
    .module3-middle-item {
      width: 198px;
      height: 80px;
      position: absolute;
      top: 0;
      &:first-of-type {
        left: 0;
        transform: translateX(-50%);
      }
      &:nth-of-type(2) {
        left: 50%;
        transform: translate(-50%, -50%);
      }
      &:last-of-type {
        right: 0;
        transform: translateX(50%);
      }

      .middle-item-box {
        display: flex;
        align-items: flex-start;
        padding: 18px 0 0 24px;
        background: linear-gradient(90deg, #ffebeb, #ffadad 100%, #ff9090 0);
        width: 100%;
        height: 100%;
        border-radius: 4px;
        transition: all 0.3s ease;
        .module3-middle-gou {
          width: 18px;
          height: 18px;
          margin-top: 3px;
        }
        .module3-middle-item-text {
          font-size: 18px;
          color: #222;
          font-weight: 400;
          width: 126px;
          margin-left: 6px;
        }
      }
    }
  }
  .module3-img {
    width: 531px;
    height: 411px;
  }
}
</style>
