/**
 * @author  XuHongli
 * @date  2022/12/27 16:26
 * @version 1.0
 * @description
 */

const NewRetailPart1_Data = {
  left: [
    '门店品牌曝光不够',
    '客户分散，潜客获取有限',
    '与客户互动差，买完即走，转换难',
    '缺少定向营销能力，客户价值难挖掘'
  ],
  right: [
    '货品不够、爆品少，员工推广积极性差',
    '线下上货节奏固定，过季处理难',
    '门店陈列SKU少，供应链深度不够',
    '线上商城自主研发能力缺少或有限'
  ]
}

export default NewRetailPart1_Data
