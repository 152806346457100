/**
 * @author  XuHongli
 * @date  2022/12/27 16:56
 * @version 1.0
 * @description
 */
const NewRetailPart2_Data = {
  left: [
    '聚合供应链',
    '数字权益',
    '智能选品库',
    '100+活动模板',
    '品牌CPC/CPM',
    '多种积分兑换方式'
  ],
  middle: [
    '提供用户福利快速拉新促活',
    '全场景营销活动精准触达用户',
    '覆盖生活场景助力流量变现'
  ],
  right: [
    '数据分析',
    '商品库管理',
    '对账结算',
    '广告投放',
    'API对接',
    '一键商城搭建',
    '自定义商城装修'
  ]
}

export default NewRetailPart2_Data
