<template>
  <div class="newRetailPart1">
    <div class="section-title wow animate__zoomIn" data-wow-duration="1s">
      零售行业痛点
    </div>
    <div class="section-content wow animate__fadeInUp" data-wow-duration="1s">
      <ul class="content-left">
        <li
          class="content-li"
          v-for="(item, index) in NewRetailPart1_Data.left"
          :key="index"
        >
          <div class="point"></div>
          <p class="module2-li-text">{{ item }}</p>
        </li>
      </ul>
      <img
        :src="
          $StaticFileUrl + '/mp/pages/Solution/newRetail/newRetail-module2.png'
        "
        alt=""
        class="content-center-img"
      />
      <ul class="content-right">
        <li
          class="content-li"
          v-for="(item, index) in NewRetailPart1_Data.right"
          :key="index"
        >
          <div class="point"></div>
          <p class="module2-li-text">{{ item }}</p>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
/**
 * @author  XuHongli
 * @date  2022/12/27 16:16
 * @version 1.0
 * @description
 */
import WOW from "wowjs";
import NewRetailPart1_Data from "@/views/Solution/NewRetail/Data/NewRetailPart1_Data";
export default {
  name: "NewRetailPart1",
  data() {
    return {
      NewRetailPart1_Data,
    };
  },
  mounted() {
    new WOW.WOW().init();
  },
};
</script>

<style scoped lang="scss">
.newRetailPart1 {
  background-color: #fff;
  padding: 86px 0 80px;
  .section-title {
    font-size: 44px;
    font-weight: 400;
    color: #222;
    text-align: center;
  }
  .section-content {
    display: flex;
    justify-content: center;
    margin-top: 83px;
    .content-left {
      padding-top: 20px;
    }
    .content-li {
      margin-bottom: 70px;
      display: flex;
      align-items: flex-start;
      .point {
        width: 6px;
        height: 6px;
        background-color: #ff6a00;
        border-radius: 50%;
        display: inline-block;
        margin-top: 12px;
        margin-right: 8px;
        position: relative;
        &::after {
          content: "";
          width: 12px;
          height: 12px;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          border-radius: 50%;
          background-color: rgba(255, 0, 23, 0.15);
        }
      }
      .module2-li-text {
        font-size: 20px;
        color: #222;
        font-weight: 400;
        display: inline-block;
        min-height: 52px;
      }
    }
    .content-center-img {
      width: 493px;
      height: 522px;
      margin: 0 75px;
    }

    .content-right {
      padding-top: 20px;
    }
  }
}
</style>
