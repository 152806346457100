<template>
  <div class="newRetail">
    <BannerImg>
      <div class="title wow fadeInDown" data-wow-duration="1s">新零售数字化服务解决方案</div>
      <div class="desc wow fadeInDown" data-wow-duration="1s">以 “在线商城+全场景化营销+品牌曝光”为一体私域流量运营服务，<br>助力零售企业GMV增长</div>
      <div class="flex-row flex-col-center">
        <div class="btn-red wow flipInX" data-wow-duration="1s" style="margin-top: 100px">立即体验</div>
        <div class="btn-red-linear wow flipInX" data-wow-duration="1s" style="margin-top: 100px">免费试用</div>
      </div>
    </BannerImg>
    <NewRetailPart1></NewRetailPart1>
    <NewRetailPart2></NewRetailPart2>
  </div>
</template>

<script>
import BannerImg from '@/components/BannerImg'
/**
 * @author  XuHongli
 * @date  2022/12/27 15:56
 * @version 1.0
 * @description
 */
import WOW from 'wowjs'
import NewRetailPart1 from '@/views/Solution/NewRetail/NewRetailPart1'
import NewRetailPart2 from '@/views/Solution/NewRetail/NewRetailPart2'
export default {
  name: 'NewRetail',
  components: {NewRetailPart2, NewRetailPart1, BannerImg},
  mounted(){
    new WOW.WOW().init()
  }
}
</script>

<style scoped>

</style>
