var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"NewRetailPart2"},[_c('div',{staticClass:"module3-bg",style:({
      backgroundImage: `url(${
        _vm.$StaticFileUrl +
        '/mp/pages/Solution/newRetail/newRetail-module3-bg.png'
      })`,
    })}),_c('div',{staticClass:"section-title"},[_vm._v("平台能力")]),_c('div',{staticClass:"section-subtitle"},[_vm._v(" 整合互动广告资源及福利权益资源，一键自定义搭建在线商城，提供多场景解决方案 ")]),_c('div',{staticClass:"module3-cont"},[_c('div',{staticClass:"module3-left"},[_c('div',{staticClass:"module3-img-box"},[_c('img',{staticClass:"module3-img",attrs:{"src":_vm.$StaticFileUrl +
            '/mp/pages/Solution/newRetail/newRetail-module3-left.png',"alt":""}})]),_c('dl',{staticClass:"module3-dl"},[_c('dt',{staticClass:"module3-dt"},[_vm._v("平台资源")]),_vm._l((_vm.NewRetailPart2_Data.left),function(item,index){return _c('dd',{key:index,staticClass:"module3-dd"},[_c('div',{staticClass:"point"}),_c('p',{staticClass:"module3-dd-text"},[_vm._v(_vm._s(item))])])})],2)]),_c('div',{staticClass:"module3-middle"},[_c('div',{staticClass:"module3-middle-top"},_vm._l((_vm.NewRetailPart2_Data.middle),function(item,index){return _c('div',{key:index,staticClass:"module3-middle-item"},[_c('div',{staticClass:"middle-item-box"},[_c('img',{staticClass:"module3-middle-gou",attrs:{"src":_vm.$StaticFileUrl +
                '/mp/pages/Solution/newRetail/newRetail-module3-gou.png',"alt":""}}),_c('p',{staticClass:"module3-middle-item-text"},[_vm._v(_vm._s(item))])])])}),0),_c('img',{staticClass:"module3-img",attrs:{"src":_vm.$StaticFileUrl +
          '/mp/pages/Solution/newRetail/newRetail-module3-img.png',"alt":""}})]),_c('div',{staticClass:"module3-right"},[_c('div',{staticClass:"module3-img-box"},[_c('img',{staticClass:"module3-img",attrs:{"src":_vm.$StaticFileUrl +
            '/mp/pages/Solution/newRetail/newRetail-module3-right.png',"alt":""}})]),_c('dl',{staticClass:"module3-dl"},[_c('dt',{staticClass:"module3-dt"},[_vm._v("平台功能")]),_vm._l((_vm.NewRetailPart2_Data.right),function(item,index){return _c('dd',{key:index,staticClass:"module3-dd"},[_c('div',{staticClass:"point"}),_c('p',{staticClass:"module3-dd-text"},[_vm._v(_vm._s(item))])])})],2)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }